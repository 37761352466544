<template>
  <div class="null-page">
    <h1>404</h1>
    <p>页面不存在</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.null-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
}
</style>